<template>
  <section class="donation">
    <FullBanner :bannerURL="$route.meta.img" :moduleName="$route.meta.name" btnJumpPageURL="/my-donation"
      btnName="My Donation">
    </FullBanner>

    <section style="height: 1000px" class="container690">
      <ul class="donation-content p-0">
        <li>
          <router-link to="/jci" tag="div"> JCI </router-link>
        </li>
        <li>
          <router-link to="/foundation" tag="div"> JCI Foundation </router-link>
        </li>
        <!-- <li>
          <router-link to="/other" tag="div"> Other </router-link>
        </li> -->
      </ul>

      <div class="ranking-card">
        <div class="ranking-header">
          <span>Ranking List</span>
          <span>Ranking by donation amount</span>
        </div>
        <hr />

        <ul class="self-tab m-0">
          <li @click="active = 'jci'" :class="{ active: active == 'jci' }">
            JCI
          </li>
          <li @click="active = 'foundation'" :class="{ active: active == 'foundation' }">
            JCI Foundation
          </li>
          <!-- <li @click="active = 'other'" :class="{ active: active == 'other' }">
            Other
          </li> -->
        </ul>
        <section id="Ranking-table" class="px-2">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column type="index">
              <template slot-scope="scope">
                <div style="display: flex; justify-content: center">
                  <div v-if="indexFilter(scope)" style="width: 27px; height: 30px">
                    <img style="
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                      " :src="
                        require('../../assets/images/page-img/donation/' +
                          parseInt(+scope.$index + 1) +
                          '.png')
                      " />
                  </div>
                  <span style="color: #333" v-else>{{ scope.$index + 1 }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="avatar" show-overflow-tooltip>
              <template slot-scope="scope">
                <div style="display: flex; align-items: center">
                  <div class="user-img img-fluid mr-2">
                    <img :src="scope.row.avatar" class="rounded-circle avatar-40" />
                  </div>
                  <p class="m-0">
                    {{ scope.row.donor_first_name + scope.row.donor_last_name }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="donation_amount" prop="donation_amount">
              <template slot-scope="scope">
                <div class="d-flex justify-content-end">
                  {{scope.row.donation_amount}}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </section>
      </div>
    </section>
  </section>
</template>

<script>
  import FullBanner from "../../components/ComComponents/FullBanner";
  export default {
    data() {
      return {
        tableData: [],
        active: "jci"
      };
    },
    filters: {

    },

    watch: {
      active(val) {
        let type = val == 'jci' ? 1 : val == 'foundation' ? 2 : 3
        // console.log(type);
        this.jciDonationRank(type);
      }
    },
    components: { FullBanner },
    created() {
      this.jciDonationRank(1);
    },
    methods: {
      indexFilter(scope) {
        return scope.$index <= 2 ? true : false
      },

      // 捐款排行
      async jciDonationRank(type) {
        let res = await this.$http.jciDonationRank({ donated_type: type })
        if (res.status == 200) {
          this.tableData = res.data;
        }
      }
    },
  };
</script>
<style lang="scss" scoped>
  .donation {
    min-height: 100vh;

    ::v-deep .right_btn {
      cursor: pointer;
    }

    .donation-content {
      >li {
        height: 120px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-bottom: 15px;
        font-size: 20px;
        color: #fff;

        &:first-child {
          background: url("../../assets/images/page-img/donation/banner1.png");
          margin-top: 15px;
        }

        &:nth-child(2) {
          background: url("../../assets/images/page-img/donation/banner2.png");
        }

        &:nth-child(3) {
          background: url("../../assets/images/page-img/donation/banner3.png");
        }

        >div {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          padding-left: 20px;
          cursor: pointer;
        }
      }
    }

    .ranking-card {
      background: #fff;
      border-radius: 5px;

      .ranking-header {
        padding: 15px 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        >span:first-child {
          color: #333;
          font-size: 16px;
        }

        >span:last-child {
          color: #999;
          font-size: 12px;
        }
      }

      .self-tab {
        display: flex;
        align-items: center;
        padding: 0 15px 20px;

        >li {
          margin-right: 20px;
          color: #50b5ff;
          cursor: pointer;

          &.active {
            background-color: #50b5ff;
            color: #fff;
            padding: 10px 35px;
            border-radius: 5px;
          }
        }
      }

      #Ranking-table {
        ::v-deep .has-gutter {
          display: none;
        }
      }
    }
  }
</style>